<template>
    <el-dialog v-if="dialogVisible" destroy-on-close center fullscreen :visible.sync="dialogVisible" width="90%">
        <div slot="title">
            <h4 class="mt">{{ $t('成绩单分析报告') }}</h4>
            <div v-if="data" class="flex flex-h-end st padding">{{ $t('考试时间') }}：{{ data.day }}</div>
        </div>
        <div style="margin-top: -40px;position: relative;" id="golas">
            <el-table :data="tableData" style="width: 100%">
                <el-table-column prop="label" :label="$t('题型')" width="100">
                    <template slot-scope="scope">
                        {{ $t(scope.row.label) }}
                    </template>
                </el-table-column>
                <el-table-column prop="score" :label="$t('得分')" width="100">
                </el-table-column>
                <el-table-column prop="value" :label="$t('目标得分')" width="100">
                </el-table-column>
                <el-table-column prop="content" :label="$t('分析结果')">
                    <template slot-scope="scope">

                        <div v-for="(item, index) in getContent(scope.row)" :key="index">{{
                            item }}</div>

                    </template>
                </el-table-column>
            </el-table>
            <div v-if="role == 0" :style="{ top: top, left: left }"
                style="position: absolute;z-index: 100;background-color: #f0f0f0;right:0;bottom: 0;display: flex;flex-direction: column;align-items: center;justify-content: center;">
                <div
                    style="display: flex;flex-direction: column;align-items: center;justify-content: center;cursor: pointer;">
                    <i style="font-size: 60px;color:#e6a13c" class="el-icon-lock"></i>
                    <el-button @click="vipAlert()" class="margin-t" round type="primary">{{ $t('成为海澳VIP，解锁全部分析') }}<i
                            class="el-icon-right"></i></el-button>
                </div>
            </div>
        </div>

        <div class="flex flex-h-center padding margin-t">
            <el-image style="width:200px;height:auto;" :src="$host + $store.state.setting.kf_qrcode"></el-image>
        </div>

        <div slot="footer">
            <el-button type="primary" round style="width:200px" @click="dialogVisible = false">{{ $t('关闭')
                }}</el-button>
        </div>

    </el-dialog>
</template>

<script>
export default {
    name: "transcriptDialog",
    data() {
        return {
            data: null,
            dialogVisible: false,
            tableData: [],
            items: [],
            rows: [
                { label: '口语', key1: 's', key2: 0 },
                { label: '写作', key1: 'w', key2: 1 },
                { label: '阅读', key1: 'r', key2: 2 },
                { label: '听力', key1: 'l', key2: 3 },
                { label: '总分', key1: 't', key2: 4 },
            ],
            top: '0',
            bottom: '0',
            left: '0',
            right: '0',
        };
    },
    computed: {
        exam_data() {
            return this.$store.state.user.exam_data || {};
        },
        role() {
            return this.$store.getters.getRole()
        }
    },
    created() {
        this.query();
    },
    methods: {
        vipAlert() {
            this.$router.push("/main/vip?flag=1")
        },
        show(data) {
            this.data = data;
            this.tableData = this.rows.map(row => {
                return {
                    label: row.label,
                    score: data[row.key1],
                    value: this.exam_data[row.key2],
                };
            });

            this.dialogVisible = true;

            if (this.role == 0) {
                this.$nextTick(() => {
                    const container = document.getElementById('golas')
                    const cells = container.getElementsByTagName('td');
                    let rect1 = container.getBoundingClientRect()
                    let rect2 = cells[7].getBoundingClientRect()
                    this.top = (rect2.top - rect1.top) + 'px'
                    this.left = (rect2.left - rect1.left) + 'px'
                })
            }

        },

        query() {
            this.$http.post("/api/transcript").then((items) => {
                this.items = items;
            })
        },
        getContent(row) {
            let nos = [];
            let diff = 0;
            let items = null;
            if (row.label === '口语') {
                if (row.score >= row.value) {
                    nos.push('1.1.0')
                } else {
                    if (this.data.items[5] >= row.value) {
                        nos.push('1.3.0')
                    } else {
                        diff = row.value - this.data.items[5];
                        items = this.items.filter(item => item.tag === '1.2');
                        for (let i = 0; i < items.length; i++) {
                            if (diff >= items[i].score) {
                                nos.push('1.2.' + items[i].score);
                                break;
                            }
                        }
                    }
                    if (this.data.items[4] >= row.value) {
                        nos.push('1.5.0')
                    } else {
                        diff = row.value - this.data.items[4];
                        items = this.items.filter(item => item.tag === '1.4');
                        for (let i = 0; i < items.length; i++) {
                            if (diff >= items[i].score) {
                                nos.push('1.4.' + items[i].score);
                                break;
                            }
                        }
                    }
                }
            } else if (row.label === '写作') {
                if (row.score >= row.value) {
                    nos.push('2.1.0')
                } else {
                    if (this.data.items[3] >= row.value) {
                        nos.push('2.3.0')
                    } else {
                        diff = row.value - this.data.items[3];
                        items = this.items.filter(item => item.tag === '2.2');
                        for (let i = 0; i < items.length; i++) {
                            if (diff >= items[i].score) {
                                nos.push('2.2.' + items[i].score);
                                break;
                            }
                        }
                    }

                    if (this.data.items[2] >= row.value) {
                        nos.push('2.5.0')
                    } else {
                        diff = row.value - this.data.items[2];
                        items = this.items.filter(item => item.tag === '2.4');
                        for (let i = 0; i < items.length; i++) {
                            if (diff >= items[i].score) {
                                nos.push('2.4.' + items[i].score);
                                break;
                            }
                        }
                    }
                }
            } else if (row.label === '阅读') {
                if (row.score >= row.value) {
                    nos.push('3.1.0')
                } else {
                    if (this.data.items[5] >= row.value) {
                        nos.push('3.3.0')
                    } else {
                        diff = row.value - this.data.items[5];
                        items = this.items.filter(item => item.tag === '3.2');
                        for (let i = 0; i < items.length; i++) {
                            if (diff >= items[i].score) {
                                nos.push('3.2.' + items[i].score);
                                break;
                            }
                        }
                    }

                    if (this.data.items[7] >= row.value) {
                        nos.push('3.5.0')
                    } else {
                        diff = row.value - this.data.items[7];
                        items = this.items.filter(item => item.tag === '3.4');
                        for (let i = 0; i < items.length; i++) {
                            if (diff >= items[i].score) {
                                nos.push('3.4.' + items[i].score);
                                break;
                            }
                        }
                    }


                    if (this.data.items[3] >= row.value) {
                        nos.push('3.7.0')
                    } else {
                        diff = row.value - this.data.items[3];
                        items = this.items.filter(item => item.tag === '3.4');
                        for (let i = 0; i < items.length; i++) {
                            if (diff >= items[i].score) {
                                nos.push('3.6.' + items[i].score);
                                break;
                            }
                        }
                    }
                }
            } else if (row.label === '听力') {
                if (row.score >= row.value) {
                    nos.push('4.1.0')
                } else {
                    if (this.data.items[5] >= row.value) {
                        nos.push('4.3.0')
                    } else {
                        diff = row.value - this.data.items[5];
                        items = this.items.filter(item => item.tag === '4.2');
                        for (let i = 0; i < items.length; i++) {
                            if (diff >= items[i].score) {
                                nos.push('4.2.' + items[i].score);
                                break;
                            }
                        }
                    }

                    if (this.data.items[3] >= row.value) {
                        nos.push('4.5.0')
                    } else {
                        diff = row.value - this.data.items[3];
                        items = this.items.filter(item => item.tag === '4.4');
                        for (let i = 0; i < items.length; i++) {
                            if (diff >= items[i].score) {
                                nos.push('4.4.' + items[i].score);
                                break;
                            }
                        }
                    }

                    if (this.data.items[4] >= row.value) {
                        nos.push('4.7.0')
                    } else {
                        diff = row.value - this.data.items[4];
                        items = this.items.filter(item => item.tag === '4.6');
                        for (let i = 0; i < items.length; i++) {
                            if (diff >= items[i].score) {
                                nos.push('4.6.' + items[i].score);
                                break;
                            }
                        }
                    }

                    if (this.data.items[2] >= row.value) {
                        nos.push('4.9.0')
                    } else {
                        diff = row.value - this.data.items[2];
                        items = this.items.filter(item => item.tag === '4.8');
                        for (let i = 0; i < items.length; i++) {
                            if (diff >= items[i].score) {
                                nos.push('4.8.' + items[i].score);
                                break;
                            }
                        }
                    }
                }
            } else if (row.label === '总分') {
                if (row.score >= row.value) {
                    nos.push('5.1.0')
                } else {
                    nos.push('5.2.0')
                }
            }
            return this.items.filter(item => nos.includes(item.no)).map(item => this.$t1(item, 'content'));
        }
    },
};
</script>
<style scoped></style>